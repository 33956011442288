<template>
    <div style="height: calc(100vh - 40px);background-color: black;padding: 20px;">
        <div style="height: calc(100vh - 40px);background-color: white">
            <div style="padding-top: 10px;height: 60px;">
                <el-steps :active="activestep" align-center style="">
                    <el-step @click="activestep = 1" title="第一步" />
                    <el-step @click="nextstep(2)" title="第二步"/>
                    <el-step @click="activestep = 3" title="第三步"/>
                </el-steps>
            </div>
            <div style="height: calc(100vh - 110px);background-color: #cecece30">
                <div v-show="activestep == 1">
                    <div class="titleclass">
                        <h2 style="margin: 0px;">基本信息</h2>
                        <div style="font-size: small;color: grey;"><span style="color: red;">*</span>代表必填</div>
                    </div>
                    <div class="contentclass">
                        <el-scrollbar always>
                            <el-form :model="baseform" :rules="baseformrules" ref="baseref">
                                <div>
                                    <div class="formtitleclass">
                                        姓名<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="name">
                                            <el-input placeholder="请输入患者姓名" v-model="baseform.name" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        电话号码<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="tel">
                                            <el-input type="tel" placeholder="请输入手机号码" maxlength="11" minlength="11" v-model="baseform.tel" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        性别<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="gender">
                                            <el-radio-group v-model="baseform.gender">
                                                <el-radio :label="1">男</el-radio>
                                                <el-radio :label="2">女</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        年龄<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="age">
                                            <el-input maxlength="3" placeholder="请输入患者年龄" v-model="baseform.age" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        身高（cm）
                                    </div>
                                    <div>
                                        <el-form-item prop="height">
                                            <el-input maxlength="5" placeholder="请输入患者身高" v-model="baseform.height" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        体重（kg）
                                    </div>
                                    <div>
                                        <el-form-item prop="weight">
                                            <el-input maxlength="5" placeholder="请输入患者体重" v-model="baseform.weight" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        职业
                                    </div>
                                    <div>
                                        <el-form-item prop="career">
                                            <el-input placeholder="请输入患者职业" v-model="baseform.career" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        受教育程度<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="edu">
                                            <el-select placeholder="请选择受教育程度" v-model="baseform.edu">
                                                <el-option v-for="(item,index) in eduselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        特殊人群<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="specialpeople">
                                            <el-select placeholder="请选择受特殊人群" v-model="baseform.specialpeople">
                                                <el-option v-for="(item,index) in specialpeopleselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        身体状况
                                    </div>
                                    <div>
                                        <el-form-item prop="bodysituation">
                                            <el-checkbox @change="sethealth" v-model="baseform.bodysituation">健康</el-checkbox>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        原发肿瘤诊断<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="cancerdialogsis">
                                            <el-input placeholder="请输入原发肿瘤诊断" v-model="baseform.cancerdialogsis" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        肿瘤转移情况
                                    </div>
                                    <div>
                                        <el-form-item prop="cancermovesituation">
                                            <el-input placeholder="请输入肿瘤转移情况" v-model="baseform.cancermovesituation" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        肿瘤治疗方式
                                    </div>
                                    <div>
                                        <el-form-item prop="tumortreatmentmethods">
                                            <el-select placeholder="请选择肿瘤治疗方式" v-model="baseform.tumortreatmentmethods">
                                                <el-option v-for="(item,index) in tumortreatmentmethodsselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        基础疾病
                                    </div>
                                    <div>
                                        <el-form-item prop="illnesslist">
                                            <div style="width:100%">
                                                <el-autocomplete
                                                    placeholder="请输入基础疾病"
                                                    style="width: 220px;"
                                                    v-model="baseform.basedisease"
                                                    clearable
                                                    :fetch-suggestions="searchIllness"
                                                    @select="handleselectillness"
                                                />
                                            </div>
                                            <div v-if="baseform.illnesslist.length>0" style="background-color: white;border-radius: 5px;border:1px solid rgb(224, 224, 224);width: 70%;height: 70px;padding: 3px;margin-top: 5px;">
                                                <el-tag style="margin:0px 3px 3px 0px" closable type="info" v-for="(item,index) in baseform.illnesslist" :key="index" @close="deleteillness(index)">
                                                    {{ item }}
                                                </el-tag>
                                            </div>
                                            <!-- <el-input placeholder="请输入基础疾病" resize="none" :rows="2" type="textarea" v-model="baseform.basedisease" style="width: 70%;"/> -->
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        肝功能<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="liverfunction">
                                            <el-select placeholder="请选择肝功能情况" v-model="baseform.liverfunction">
                                                <el-option v-for="(item,index) in liverorrenalfunctionselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        肾功能<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="renalfunction">
                                            <el-select placeholder="请选择肾功能情况" v-model="baseform.renalfunction">
                                                <el-option v-for="(item,index) in liverorrenalfunctionselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        心功能<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="heartfunction">
                                            <el-select placeholder="请选择心功能情况" v-model="baseform.heartfunction">
                                                <el-option v-for="(item,index) in heartfunctionselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        过敏史<span style="color: red;">*</span>（药物/食物）
                                    </div>
                                    <div>
                                        <el-form-item prop="allergyhistory">
                                            <el-input placeholder="请输入过敏史" v-model="baseform.allergyhistory" style="width: 70%;"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        身体状况<span style="color: red;">*</span>（EQ-5D-3L量表）
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass2">
                                        行动
                                    </div>
                                    <div>
                                        <el-form-item prop="action">
                                            <el-select placeholder="请选择身体状况" @change="userphysicalchange" v-model="baseform.action" style="width: 40%;min-width: 250px;">
                                                <el-option v-for="(item,index) in actionselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass2">
                                        自己照顾自己
                                    </div>
                                    <div>
                                        <el-form-item prop="takecare">
                                            <el-select placeholder="请选择身体状况" @change="userphysicalchange" v-model="baseform.takecare" style="width: 40%;min-width: 250px;">
                                                <el-option v-for="(item,index) in takecareselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass2">
                                        日常活动（如工作，学习，家务事，家庭或休闲活动）
                                    </div>
                                    <div>
                                        <el-form-item prop="activities">
                                            <el-select placeholder="请选择身体状况" @change="userphysicalchange" v-model="baseform.activities" style="width: 40%;min-width: 250px;">
                                                <el-option v-for="(item,index) in activitiesselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass2">
                                        疼痛/不舒服
                                    </div>
                                    <div>
                                        <el-form-item prop="painornot">
                                            <el-select placeholder="请选择身体状况" @change="userphysicalchange" v-model="baseform.painornot" style="width: 40%;min-width: 250px;">
                                                <el-option v-for="(item,index) in painornotselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass2">
                                        焦虑（如紧张、担心、不安等等）/抑郁（如做事情缺乏兴趣、没乐趣、提不起精神等）
                                    </div>
                                    <div>
                                        <el-form-item prop="anxious">
                                            <el-select placeholder="请选择身体状况" @change="userphysicalchange" v-model="baseform.anxious" style="width: 40%;min-width: 250px;">
                                                <el-option v-for="(item,index) in anxiousselect" :key="index" :label="item.label" :value="item.value"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div v-if="baseform.physicalscore!=null" class="formtitleclass2">
                                        效用值：{{ baseform.physicalscore }}
                                    </div>
                                </div>
                            </el-form>
                        </el-scrollbar>
                    </div>
                    <div class="btnareaclass">
                        <div>
                            <div style="text-align: right;">
                                <el-button @click="commitbaseform('baseref')" style="font-size: large;height: 40px;width: 100px;font-weight: bold;margin-right: 20px;" type="primary">下一步</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="activestep == 2">
                    <div class="titleclass">
                        <h2 style="margin: 0px;">疼痛评估</h2>
                        <div style="font-size: small;color: grey;"><span style="color: red;">*</span>代表必填</div>
                    </div>
                    <div class="contentclass">
                        <el-scrollbar always>
                            <el-form :model="painform" ref="painref" :rules="painformrules">
                                <div>
                                    <div class="formtitleclass">
                                        疼痛原因<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="painreason">
                                            <el-checkbox-group v-model="painform.painreason">
                                                <el-checkbox v-for="(item,index) in painReasonList" :key="index" :label="item[1]">{{ item[0] }}</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        疼痛部位<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <div style="height: 400px;width: 400px;" id="echart1"></div>
                                        <el-form-item prop="painpart">
                                            <div style="background-color: white;border:1px solid rgb(199, 199, 199);width: 60%;min-height: 40px;border-radius: 5px;padding: 3px;">
                                                <div style="width:min-content;display: inline-block;" v-for="(part,index) in painform.painpart" :key="index">
                                                    <el-tag type="info" style="margin-left: 5px;" v-if="part.selected">{{ part.name }}</el-tag>
                                                </div>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        其他疼痛说明
                                    </div>
                                    <div>
                                        <el-form-item prop="painextra">
                                            <el-input placeholder="请输入其他疼痛说明" v-model="painform.painextra" style="max-width: 800px;"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="formtitleclass2" style="margin-top: 15px;margin-bottom: 15px;">
                                    {{ " ID Pian量表评分：" + painScore }}
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        疼痛性质<span style="color: red;">*</span>（可多选）
                                    </div>
                                    <div>
                                        <el-form-item prop="paincharacter">
                                            <el-checkbox-group v-model="painform.paincharacter" >
                                                <el-checkbox  @change="painpartorchchange()" v-for="(item,index) in painpropertielist" :key="index" :label="item[1]">{{ item[0] }}</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        疼痛强度（过去24小时平均疼痛）
                                    </div>
                                    <div>
                                        <div style="max-width: 500px;">
                                            <object ref="painhardref" :data="painhardsvgPath" type="image/svg+xml"></object>
                                        </div>
                                        <el-form-item prop="painmostlevel">
                                            <el-slider @change="changesvgstatus" style="margin-left: 15px;margin-right: 15px;margin-bottom: 20px;height: 40px;max-width: 500px;" v-model="painform.painmostlevel" :step="1" :max="10" show-stops :marks="painhardslidermark"/>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        疼痛加重因素<span style="color: red;">*</span>（可多选）
                                    </div>
                                    <div>
                                        <el-form-item prop="aggravationfactors">
                                            <el-checkbox-group v-model="painform.aggravationfactors">
                                                <el-checkbox  @change="changetowu(painform.aggravationfactors,item[0],-1,'2')" v-for="(item,index) in painaggravatelist" :key="index" :label="item[1]">{{ item[0] }}</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        疼痛缓解因素<span style="color: red;">*</span>（可多选）
                                    </div>
                                    <div>
                                        <el-form-item prop="relieffactors">
                                            <el-checkbox-group v-model="painform.relieffactors">
                                                <el-checkbox @change="changetowu(painform.relieffactors,item[0],-1,'3')" v-for="(item,index) in painretardlist" :key="index" :label="item[1]">{{ item[0] }}</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        疼痛爆发类型<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item prop="breakouttype">
                                            <!-- <el-checkbox-group v-model="painform.breakouttype">
                                                <el-checkbox v-for="(item,index) in paintypelist" :key="index" :label="item[0]"/>
                                            </el-checkbox-group> -->
                                            <el-radio-group v-model="painform.breakouttype">
                                                <el-radio v-for="(item,index) in paintypelist" :key="index" :label="item[1]">{{item[0]}}</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        疼痛爆发次数（每天）
                                        <!-- <span style="color: red;">*</span> -->
                                    </div>
                                    <div>
                                        <el-form-item prop="breakoutfreq">
                                            <!-- <el-checkbox-group v-model="painform.breakoutfreq">
                                                <el-checkbox v-for="(item,index) in paintimelist" :key="index" :label="item"/>
                                            </el-checkbox-group> -->
                                            <el-radio-group @change="breakouttimetowu" v-model="painform.breakoutfreq">
                                                <el-radio v-for="(item,index) in paintimelist" :key="index" :label="item[1]">{{ item[0] }}</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        疾病
                                        <!-- <span style="color: red;">*</span> -->
                                        （可多选）
                                    </div>
                                    <div>
                                        <el-form-item prop="painillness">
                                            <el-checkbox-group v-model="painform.painillness">
                                                <el-checkbox @change="changetowu(painform.painillness,item[0],0,'4')" v-for="(item,index) in painillness" :key="index" :label="item[1]">{{ item[0] }}</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div>
                                    <div class="formtitleclass">
                                        症状
                                        <!-- <span style="color: red;">*</span> -->
                                        （可多选）
                                    </div>
                                    <div>
                                        <el-form-item prop="painstatus">
                                            <el-checkbox-group v-model="painform.painstatus">
                                                <el-checkbox @change="changetowu(painform.painstatus,item[0],0,'5')" v-for="(item,index) in painstatus" :key="index" :label="item[1]">{{ item[0] }}</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form>
                        </el-scrollbar>
                    </div>
                    <div class="btnareaclass">
                        <div>
                            <el-row>
                                <el-col :span="12">
                                    <el-button @click="prestep(1)" style="font-size: large;height: 40px;width: 100px;font-weight: bold;margin-left: 20px;" type="info" plain>上一步</el-button>
                                </el-col>
                                <el-col :span="12" style="text-align: right;">
                                    <el-button @click="commitpainform('painref')" style="font-size: large;height: 40px;width: 100px;font-weight: bold;margin-right: 20px;" type="primary">下一步</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div v-show="activestep == 3">
                    <div class="titleclass">
                        <h2 style="margin: 0px;">既往用药情况</h2>
                        <div style="font-size: small;color: grey;"><span style="color: red;">*</span>代表必填，单击[增加项]按钮添加用药，选中行后单击[删除项]按钮删除用药</div>
                    </div>
                    <div class="contentclass">
                        <el-scrollbar always>
                            <el-form :model="historyform" :rules="historyformrules" ref="historyref">
                                <div>
                                    <div class="formtitleclass">
                                        既往是否使用过镇痛药<span style="color: red;">*</span>
                                    </div>
                                    <div>
                                        <el-form-item>
                                            <el-radio-group v-model="historyform.havausedrug">
                                                <el-radio label="是"/>
                                                <el-radio label="否"/>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div v-show="historyform.havausedrug == '是'">
                                    <div>
                                        <el-button @click="openincreasedrugdialog('used')">增加项</el-button>
                                        <el-button @click="deleteitem('used')">删除项</el-button>
                                    </div>
                                    <div style="margin-top: 10px;margin-bottom: 10px;">
                                        <el-form-item prop="useddrugtable">
                                            <el-table empty-text="无既往用药数据" @row-click="handleselectrow" :row-class-name="tableRowClassName" style="width:70%;min-width: 280px;" :data="historyform.useddrugtable">
                                                <el-table-column prop="drugname" label="用药名称" width="220" />
                                                <el-table-column prop="drugdose" label="单次用药剂量" width="120" />
                                                <el-table-column prop="drugtime" label="用药频次" width="120"/>
                                                <el-table-column prop="drugstartendtime" label="用药起止时长"  width="120"/>
                                                <el-table-column label="操作" width="200">
                                                    <template #default="scope">
                                                        <el-button @click="edituseddrug(scope.$index)">编辑</el-button>
                                                        <el-button @click="deleteuseddrug(scope.$index)">删除</el-button>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </el-form-item>
                                    </div>
                                    <div>
                                        <div class="formtitleclass">
                                            不良反应<span style="color: red;">*</span>
                                        </div>
                                        <div>
                                            <el-form-item prop="adverseReaction">
                                                <el-checkbox-group v-model="historyform.adverseReaction" >
                                                    <el-checkbox @change="changetowu(historyform.adverseReaction,item[0],0,'1')" v-for="(item,index) in adversereactions" :key="index" :label="item[1]">{{ item[0] }}</el-checkbox>
                                                </el-checkbox-group>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="formtitleclass">
                                            不良反应处理用药
                                        </div>
                                        <div>
                                            <el-form-item prop="adverseReactionDrugs">
                                                <div style="width:100%">
                                                    <el-autocomplete
                                                        placeholder="请输入不良反应处理用药"
                                                        style="width: 220px;"
                                                        v-model="historyform.adverseReactionDrug"
                                                        clearable
                                                        :fetch-suggestions="searchadverseReactiondrugs"
                                                        @select="handleselectadverseReactiondrug"
                                                    />
                                                </div>
                                                <div v-if="historyform.adverseReactionDrugs.length>0" style="background-color: white;border-radius: 5px;border:1px solid rgb(224, 224, 224);width: 70%;height: 70px;padding: 3px;margin-top: 5px;">
                                                    <el-tag style="margin:0px 3px 3px 0px" closable type="info" v-for="(item,index) in historyform.adverseReactionDrugs" :key="index" @close="deletetag(index)">
                                                        {{ item }}
                                                    </el-tag>
                                                </div>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="formtitleclass">
                                            依从性<span style="color: red;">*</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="formtitleclass2">
                                            您是否有时会忘记服药?
                                        </div>
                                        <div>
                                            <el-form-item prop="complianceq1">
                                                <el-radio-group v-model="historyform.complianceq1">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="0">否</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="formtitleclass2">
                                            您是否有时不注意服药?
                                        </div>
                                        <div>
                                            <el-form-item prop="complianceq2">
                                                <el-radio-group v-model="historyform.complianceq2">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="0">否</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="formtitleclass2">
                                            您自觉症状好转时是否会自行停药?
                                        </div>
                                        <div>
                                            <el-form-item prop="complianceq3">
                                                <el-radio-group v-model="historyform.complianceq3">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="0">否</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="formtitleclass2">
                                            您服药后自觉症状更糟时是否曾停止服药?
                                        </div>
                                        <div>
                                            <el-form-item prop="complianceq4">
                                                <el-radio-group v-model="historyform.complianceq4">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="0">否</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </el-scrollbar>
                    </div>
                    <div class="btnareaclass">
                        <div>
                            <el-row>
                                <el-col :span="12">
                                    <el-button @click="prestep(2)" style="font-size: large;height: 40px;width: 100px;font-weight: bold;margin-left: 20px;" type="info" plain>上一步</el-button>
                                </el-col>
                                <el-col :span="12" style="text-align: right;">
                                    <el-button @click="finalsubmit" style="font-size: large;height: 40px;width: 100px;font-weight: bold;margin-right: 20px;" type="primary">提交</el-button>
                                    <!-- <el-button @click="resetAllform">重置</el-button> -->
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <el-dialog style="max-width: 350px;min-width: 300px;" v-model="showincreaseitemdialog">
                        <template #header>
                            <div style="font-weight: bold;">
                                增加用药
                            </div>
                        </template>
                        <el-form :model="increaseitemform" ref="increaseref" :rules="increaseitemrules">
                            <div>
                                <div class="formtitleclass">
                                    用药名称
                                </div>
                                <div style="margin: 10px;">
                                    <el-form-item prop="drugname">
                                        <el-autocomplete
                                            style="width: 220px;"
                                            v-model="increaseitemform.drugname"
                                            clearable
                                            :fetch-suggestions="searchdrugs"
                                            @select="handleselectdrug"
                                        />
                                    </el-form-item>
                                </div>
                            </div>
                            <div>
                                <div class="formtitleclass">
                                    单次用药剂量
                                </div>
                                <div v-show="increaseitemform.drugunit!==''" style="margin: 10px;">
                                    <el-form-item prop="dose">
                                        <!-- <el-input /> -->
                                        <el-input maxlength="3" style="width: 60px;" v-model="increaseitemform.dose"  />{{ increaseitemform.drugunit }}
                                    </el-form-item>
                                </div>
                            </div>
                            <div>
                                <div class="formtitleclass">
                                    用药频次
                                </div>
                                <div style="margin: 10px;">
                                    <el-form-item prop="drugtimestr">
                                        <div v-if="increaseitemform.drugtimetype === 1" style="display: inline-block;">
                                            一天<el-input maxlength="2" style="width: 60px;" v-model="increaseitemform.drugtimestr" />次
                                        </div>
                                        <div v-else-if="increaseitemform.drugtimetype === 2" style="display: inline-block;">
                                            每<el-input maxlength="2" style="width: 60px;" v-model="increaseitemform.drugtimestr" />小时/次
                                        </div>
                                        <div v-else-if="increaseitemform.drugtimetype === 3" style="display: inline-block;">
                                            <el-input maxlength="2" style="width: 60px;" v-model="increaseitemform.drugtimestr" />天/贴
                                        </div>
                                        <div v-else-if="increaseitemform.drugtimetype === 4" style="display: inline-block;">
                                            prn（必要时）
                                        </div>
                                        <div v-else-if="increaseitemform.drugtimetype === 5" style="display: inline-block;">
                                            每晚
                                        </div>
                                        <div style="display: inline-block;margin-left: 10px;">
                                            <el-dropdown style="" trigger="click">
                                                <el-icon style="background-color: rgb(238, 238, 238);border: 1px solid grey;margin-top: 8px;">
                                                    <arrow-down />
                                                </el-icon>
                                                <template #dropdown>
                                                    <el-dropdown-menu>
                                                        <el-dropdown-item @click="handleselectdrugtime(1)">一天X次</el-dropdown-item>
                                                        <el-dropdown-item @click="handleselectdrugtime(2)">每X小时/次</el-dropdown-item>
                                                        <el-dropdown-item @click="handleselectdrugtime(3)">X天/贴</el-dropdown-item>
                                                        <el-dropdown-item @click="handleselectdrugtime(4)">prn（必要时）</el-dropdown-item>
                                                        <el-dropdown-item @click="handleselectdrugtime(5)">每晚</el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </template>
                                            </el-dropdown>
                                        </div>
                                    </el-form-item>
                                </div>
                            </div>
                            <div v-if="increaseusedorrecipe === false">
                                <div class="formtitleclass">
                                    用药起止时长
                                </div>
                                <div style="margin: 10px;">
                                    <el-form-item prop="startendtime">
                                        <el-radio-group v-model="increaseitemform.startendtime">
                                            <el-radio :label="1">>7</el-radio>
                                            <el-radio :label="2">≤7</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </div>
                        </el-form>
                        <template #footer>
                            <el-button type="primary" @click="increaseitem()">确定</el-button>
                            <el-button @click="showincreaseitemdialog = false">取消</el-button>
                        </template>
                    </el-dialog>
                    <el-dialog class="dialogclass" style="width: 70vw;min-width: 300px;" v-model="showdecideaccistresultdialog">
                        <template #header>
                            <div style="font-weight: bold;">
                                辅助决策结果
                            </div>
                        </template>
                        <div>
                            <div>
                                <div class="formtitleclass">
                                    既往用药存在问题及原因
                                </div>
                                <div style="margin: 10px;">
                                    {{ drugIssueText }}
                                </div>
                            </div>
                            <div>
                                <div class="formtitleclass">
                                    系统用药决策方案推荐
                                </div>
                                <div style="margin: 10px;color: rgb(180, 39, 39);">
                                    {{ drugRecommendText + (drugRecommendText2 == "" ? drugRecommendText2 : ("，" + drugRecommendText2)) }}
                                </div>
                            </div>
                            <div v-if="suggestionDrugs.length>0">
                                <div class="formtitleclass">
                                    系统用药推荐
                                </div>
                                <!-- <div style="margin: 10px;">
                                    <el-tag style="margin-right: 10px;" v-for="(item,index) in suggestionDrugs" :key="index">
                                        {{ item }}
                                    </el-tag>
                                </div> -->
                                <div style="margin: 10px;" v-for="(item,index) in suggestionTypeDrugs" :key="index">
                                    <div style="margin: 5px;">{{  painDrugTypeNameList[item.type] + "药物推荐：" }}</div>
                                    <div>
                                        <el-tag style="margin-right: 10px;" v-for="(drugitem,drugindex) in item.drugs" :key="drugindex">
                                            {{ drugitem }}
                                        </el-tag>
                                    </div>
                                </div>
                            </div>
                            <div style="border-bottom: 1px solid rgb(197, 197, 197);">
                                <div class="formtitleclass">
                                    用药选择列表
                                </div>
                                <div style="margin: 10px;">
                                    <el-checkbox v-model="decisionform.PCNEcheck">取消PCNE检查</el-checkbox>
                                </div>
                            </div>
                            <div style="margin-top: 10px;">
                                <div>
                                    <el-button @click="openincreasedrugdialog('recipe')">增加项</el-button>
                                    <el-button @click="deleteitem('recipe')">删除项</el-button>
                                </div>
                            </div>
                            <div>
                                <el-table empty-text="无决策用药数据" @row-click="handleselectrow" :row-class-name="tableRowClassName" :data="decisionform.decisiondrugtable">
                                    <el-table-column prop="drugname" label="用药名称" width="220" />
                                    <el-table-column prop="drugdose" label="单次用药剂量" width="120" />
                                    <el-table-column prop="drugtime" label="用药频次" width="120 "/>
                                </el-table>
                            </div>
                            <div style="border-bottom: 1px solid rgb(197, 197, 197);">
                                <div class="formtitleclass">
                                    证据来源：
                                </div>
                                <div style="white-space: pre-wrap;margin: 10px;">
                                    {{ decisionRef }} 
                                </div>
                            </div>
                        </div>
                        <template #footer>
                            <el-button type="primary" @click="handlesubmitDecision">确定</el-button>
                            <el-button @click="showdecideaccistresultdialog = false">取消</el-button>
                        </template>
                    </el-dialog>
                    <el-dialog @closed="whenfeedbackclose" v-model="showFeedbackDialog">
                        <template #header>
                            <div style="font-weight: bold;">
                                用户使用评价调查表
                            </div>
                        </template>
                        <div>
                            <div class="formtitleclass">
                                您对该系统此次的推荐用药方案是否认可？
                            </div>
                            <div>
                                <el-rate v-model="feedbackstar" />                     
                            </div>
                            <div>
                                <div :style="{fontWeight: feedbackstar === 5 ? 'bold' : ''}"> · 5分：非常认可</div>
                                <div :style="{fontWeight: feedbackstar === 4 ? 'bold' : ''}"> · 4分：认可</div>
                                <div :style="{fontWeight: feedbackstar === 3 ? 'bold' : ''}"> · 3分：一般</div>
                                <div :style="{fontWeight: feedbackstar === 2 ? 'bold' : ''}"> · 2分：不认可</div>
                                <div :style="{fontWeight: feedbackstar === 1 ? 'bold' : ''}"> · 1分：非常不认可</div>
                            </div>
                        </div>
                        <template #footer>
                            <el-button type="primary" @click="handlesubmitFeedback">确定</el-button>
                            <el-button @click="showFeedbackDialog = false">取消</el-button>
                        </template>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import axios from 'axios'
import theutils from "@/utils/index.js"
import { extractS1Feat,interS1,interS2 } from "@/utils/algorithm.js"
import {getHello,postDiagnostic,updateDiagnosticByUUId,postPatientBasicInfo,postPainAssessmentInfo,postPrevMedicationInfo,postDecisionInfo,getSuggestionDrugs} from "@/api/request.js"
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';

export default {
    data(){
        return {
            activestep: 1,   //活跃步骤

            eduselect: [
                {
                    value: "1",
                    label: '小学及以下'
                },
                {
                    value: "2",
                    label: '初中'
                },
                {
                    value: "3",
                    label: '高中'
                },
                {
                    value: "4",
                    label: '专科/高职'
                },
                {
                    value: "5",
                    label: '本科'
                },
                {
                    value: "6",
                    label: '硕士'
                },
                {
                    value: "7",
                    label: '博士'
                },
            ],
            specialpeopleselect: [
                {
                    value: "1",
                    label: '老年人(65岁及以上)'
                },
                {
                    value: "2",
                    label: '儿童(14岁及以下)'
                },
                {
                    value: "3",
                    label: '孕妇'
                },
                {
                    value: "4",
                    label: '哺乳期妇女'
                },
                {
                    value: "5",
                    label: '无'
                },
            ],
            tumortreatmentmethodsselect: [
                {
                    value: "1",
                    label: '外科切除'
                },
                {
                    value: "2",
                    label: '放射治疗'
                },
                {
                    value: "3",
                    label: '中医药治疗'
                },
                {
                    value: "4",
                    label: '免疫治疗'
                },
                {
                    value: "5",
                    label: '靶向治疗'
                },
                {
                    value: "6",
                    label: '介入治疗'
                },
            ],
            liverorrenalfunctionselect: [
                {
                    value: "1",
                    label: '正常'
                },
                {
                    value: "2",
                    label: '不全'
                },
                {
                    value: "3",
                    label: '严重不全'
                },
                {
                    value: "-1",
                    label: '不详'
                },
            ],
            heartfunctionselect: [
                {
                    value: "1",
                    label: '正常'
                },
                {
                    value: "2",
                    label: 'Ⅰ级'
                },
                {
                    value: "3",
                    label: 'Ⅱ级'
                },
                {
                    value: "4",
                    label: 'Ⅲ级'
                },
                {
                    value: "5",
                    label: 'Ⅳ级'
                },
                {
                    value: "-1",
                    label: '不详'
                },
            ],
            actionselect:[
                {
                    value: 1,
                    label: '可以四处走动，没有任何困难'
                },
                {
                    value: 2,
                    label: '行动有些不方便'
                },
                {
                    value: 3,
                    label: '不能下床活动'
                },
            ],
            takecareselect:[
                {
                    value: 1,
                    label: '能自己照顾自己，没有任何困难'
                },
                {
                    value: 2,
                    label: '在洗脸、刷牙、洗澡或穿衣方面有些困难'
                },
                {
                    value: 3,
                    label: '无法自己洗脸、刷牙、洗澡或穿衣'
                },
            ],
            activitiesselect: [
                {
                    value: 1,
                    label: '能进行日常活动，没有任何困难'
                },
                {
                    value: 2,
                    label: '进行日常活动时有些困难'
                },
                {
                    value: 3,
                    label: '无法进行日常活动'
                },
            ],
            painornotselect: [
                {
                    value: 1,
                    label: '没有任何疼痛或不舒服'
                },
                {
                    value: 2,
                    label: '觉得中度疼痛或者不舒服'
                },
                {
                    value: 3,
                    label: '觉得极度疼痛或者不舒服'
                },
            ],
            anxiousselect: [
                {
                    value: 1,
                    label: '不觉得焦虑或抑郁'
                },
                {
                    value: 2,
                    label: '觉得中度焦虑或抑郁'
                },
                {
                    value: 3,
                    label: '觉得极度焦虑或抑郁'
                },
            ],

            baseform: {
                name: null,
                tel: null,
                gender: 1,
                age: null,
                height: null,
                weight: null,
                career: null,
                edu: null,
                specialpeople: null,
                bodysituation: false,
                cancerdialogsis: null,
                cancermovesituation: null,
                tumortreatmentmethods: null,
                basedisease: null,
                liverfunction: null,
                renalfunction: null,
                heartfunction: null,
                allergyhistory: null,
                action: null,
                takecare: null,
                activities: null,
                painornot: null,
                anxious: null,
                physicalscore: null,

                illness: null,
                illnesslist: [],
            },
            painform: {
                painreason: [],
                painpart:[],
                paincharacter: [],
                painmostlevel: 0,
                painextra: null,
                aggravationfactors: [],
                relieffactors: [],
                breakouttype: null,
                breakoutfreq: null,
                painillness: [],
                painstatus: [],
            },
            historyform:{
                havausedrug: '否',
                adverseReactionDrug: null,
                adverseReactionDrugs: [],
                adverseReaction: [],
                complianceq1: null,
                complianceq2: null,
                complianceq3: null,
                complianceq4: null,
                useddrugtable: [],
            },
            baseformrules: {
                name: [
                    {required: true,message: '请输入姓名',trigger: 'blur'},
                    {validator:this.namevalidatevalue,trigger: 'blur'},
                ],
                tel: [
                    {required: true,message: '请输入手机号',trigger: 'blur'},
                    {validator:this.validatevalue,trigger: 'blur'},
                ],
                age: [
                    {required: true,message: '请输入年龄',trigger: 'blur'},
                    {validator:this.validatevalue,trigger: 'blur'},
                ],
                height:[
                    {validator:this.validatevalue,trigger: 'blur'},
                ],
                weight:[
                    {validator:this.validatevalue,trigger: 'blur'},
                ],
                edu:[
                    {required: true,message: '请选择受教育程度',trigger: 'change'},
                ],
                specialpeople:[
                    {required: true,message: '请选择特殊人群',trigger: 'change'},
                ],
                cancerdialogsis:[
                    {required: true,message: '请输入原发肿瘤诊断',trigger: 'blur'},
                ],
                liverfunction:[
                    {required: true,message: '请选择肝功能',trigger: 'change'},
                ],
                renalfunction:[
                    {required: true,message: '请选择肾功能',trigger: 'change'},
                ],
                heartfunction:[
                    {required: true,message: '请选择心功能',trigger: 'change'},
                ],
                allergyhistory:[
                    {required: true,message: '请输入过敏史',trigger: 'blur'},
                ],
                action:[
                    {required: true,message: '请选择行动能力',trigger: 'change'},
                ],
                takecare:[
                    {required: true,message: '请选择自足能力',trigger: 'change'},
                ],
                activities:[
                    {required: true,message: '请选择日常活动能力',trigger: 'change'},
                ],
                painornot:[
                    {required: true,message: '请选择疼痛情况',trigger: 'change'},
                ],
                anxious:[
                    {required: true,message: '请选择精神情况',trigger: 'change'},
                ],

                illness:[
                    {required: false,message: '请选择基础疾病',trigger: 'blur'},
                ],
                illnesslist:[
                    {required: false,message: '请选择基础疾病',trigger: 'blur'},
                ],
                career:[
                    {required: false,message: '请选择职业',trigger: 'blur'},
                ],
            },
            painformrules: {
                painreason: [
                    {required: true,message: '请选择疼痛原因',trigger: 'change'},
                ],
                painpart:[
                    {required: true,message: '请选择疼痛部位',trigger: 'blur'},
                ],
                paincharacter: [
                    {required: true,message: '请选择疼痛性质',trigger: 'change'},
                ],
                painmostlevel: [
                    {required: true,message: '请选择疼痛强度',trigger: 'change'},
                ],
                aggravationfactors: [
                    {required: true,message: '请选择疼痛加重因素',trigger: 'change'},
                ],
                relieffactors: [
                    {required: true,message: '请选择疼痛缓解因素',trigger: 'change'},
                ],
                breakouttype: [
                    {required: true,message: '请选择疼痛爆发类型',trigger: 'change'},
                ],
                breakoutfreq: [
                    {required: false,message: '请选择疼痛爆发次数',trigger: 'change'},
                ],
                painillness: [
                    {required: false,message: '请选择疾病',trigger: 'change'},
                ],
                painstatus: [
                    {required: false,message: '请选择症状',trigger: 'change'},
                ],
            },
            historyformrules:{
                adverseReaction: [
                    {required: true,message: '请选择不良反应类型',trigger: 'change'},
                ],
                complianceq1: [
                    {required: true,message: '请回答是否忘记服药',trigger: 'change'},
                ],
                complianceq2: [
                    {required: true,message: '请回答是否不注意服药',trigger: 'change'},
                ],
                complianceq3: [
                    {required: true,message: '请回答是否好转时自行停药',trigger: 'change'},
                ],
                complianceq4: [
                    {required: true,message: '请回答是否症状更糟时曾停止服药',trigger: 'change'},
                ],
                useddrugtable: [
                    {required: true,message: '请添加使用过的药物',trigger: 'change'},
                ],
            },

            decisionform:{
                PCNEcheck: false,
                decisiondrugtable: []
            },
            increaseitemform: {
                drugname: '',
                dose: null,
                startendtime: null,
                drugtimestr: null,
                drugtimetype: null,
                drugunit: ''
            },
            increaseitemrules:{
                drugname: [
                    {required: true,message: '请选择正确的药物',trigger: 'blur'},
                    {validator:this.increateitemvalidatevalue,trigger: 'blur'},
                ],
                dose: [
                    {required: true,message: '请输入用药剂量',trigger: 'blur'},
                    {validator:this.increateitemvalidatevalue,trigger: 'blur'},
                ],
                startendtime: [
                    {validator:this.increateitemvalidatevalue,trigger: 'change'},
                ],
                drugtimestr: [
                    {validator:this.increateitemvalidatevalue,trigger: 'change'},
                ],
            },

            painReasonList:[
                ["肿瘤", 1],
                ["肿瘤治疗", 2],
                ["非肿瘤相关性", 3],
                ["未知", -1],
            ],
            painpropertielist:[
                ["酸痛",1],
                ["刺痛",2],
                ["跳痛",3],
                ["钝痛",4],
                ["绞痛",5],
                ["胀痛",6],
                ["坠痛",7],
                ["钻顶样痛",8],
                ["爆裂样痛",9],
                ["撕裂样痛",10],
                ["牵拉样痛",11],
                ["压榨样痛",12],
                ["放电样痛",13],
                ["烧灼样痛",15],
                ["麻木样痛",16],
                ["刀割样痛",17],
                ["轻触痛",19],
                ["无名痛",23],
                ["隐痛",24],
                ["尖锐痛",25]
            ],
            painaggravatelist:[
                ["行走", 1],
                ["活动", 2],
                ["体位变化", 3],
                ["排便", 4],
                ["咳嗽", 5],
                ["进食", 6],
                ["天气", 7],
                ["乏力", 8],
                ["精神因素", 9],
                ["无", -1],
            ],
            painretardlist:[
                ["服用镇痛药", 1],
                ["环境安静", 2],
                ["光线柔和", 3],
                ["温度适宜", 4],
                ["心理积极", 5],
                ["家人陪伴", 6],
                ["无", -1],
            ],
            paintypelist:[
                ["与特定活动或事件相关联", 1],
                ["发生在按时给予镇痛药物的剂量间隔结束时", 2],
                ["控制不佳的持续性疼痛", -2],
                ["无", 3],
            ],
            paintimelist:[
                ["≥3或持续性疼痛",1],
                ["<3",2],
                // ["无",3], 
            ],
            painillness:[
                ["心源性哮喘",1],
                // ["心血管风险",2],
                // ["肠胃道风险",3],
                ["高血压",2],
                ["糖尿病",3],
                // ["心力衰竭",4],
                // ["心肌梗塞",5],
                // ["卒中",6],
                ["心血管事件史",4],
                ["消化道出血(史)",5],
                ["消化道溃疡(史)",6],
                ["其他",-1],
                // ["无",0],
            ],
            painstatus:[
                ["咳嗽",1],
                ["寒战",2],
                // ["无",0],
            ],

            bodypart: [
                { name: '面部', value: 1,selected: false },
                { name: '头后部', value: 2,selected: false},
                { name: '右上臂（内侧）', value: 3,selected: false },
                { name: '右上臂（外侧）', value: 4 ,selected: false},
                { name: '左上臂（内侧）', value: 5,selected: false },
                { name: '左上臂（外侧）', value: 6 ,selected: false},
                { name: '右前臂（内侧）', value: 7,selected: false},
                { name: '右前臂（外侧）', value: 8 ,selected: false},
                { name: '左前臂（内侧）', value: 9,selected: false},
                { name: '左前臂（外侧）', value: 10,selected: false },
                { name: '右手', value: 11,selected: false },
                { name: '左手', value: 12,selected: false},
                { name: '颈胸部', value: 13,selected: false },
                { name: '颈背部', value: 14,selected: false },
                { name: '腹部（前）', value: 15,selected: false },
                { name: '腹部（后）', value: 16 ,selected: false},
                { name: '腰部（前）', value: 17,selected: false },
                { name: '腰部（后）', value: 18,selected: false},
                { name: '盆部（右）', value: 19,selected: false },
                { name: '腰骶部（右）', value: 20,selected: false },
                { name: "臀部（右）", value: 21 ,selected: false},
                { name: '盆部（左）', value: 22,selected: false },
                { name: '腰骶部（左）', value: 23,selected: false },
                { name: '臀部（左）', value: 24 ,selected: false},
                { name: '大腿（右前）', value: 25,selected: false },
                { name: '大腿（右后）', value: 26 ,selected: false},
                { name: '大腿（左前）', value: 27,selected: false },
                { name: '大腿（左后）', value: 28 ,selected: false},
                { name: '小腿（右前）', value: 29,selected: false },
                { name: '小腿（右后）', value: 30,selected: false },
                { name: '小腿（左前）', value: 31,selected: false},
                { name: '小腿（左后）', value: 32 ,selected: false},
                { name: '右足', value: 33,selected: false},
                { name: '左足', value: 34,selected: false },
            ],
            // bodypart: [
            //     { name: '右前臂（内侧）', value: 7,selected: false},
            //     { name: '左前臂（内侧）', value: 9,selected: false},
            //     { name: '左手', value: 12,selected: false},
            //     { name: '右足', value: 33,selected: false},
            //     { name: '小腿（左前）', value: 31,selected: false},
            //     { name: '左足', value: 34,selected: false },
            //     { name: '面部', value: 1,selected: false },
            //     { name: '左上臂（内侧）', value: 5,selected: false },
            //     { name: '颈胸部', value: 13,selected: false },
            //     { name: '盆部（右）', value: 19,selected: false },
            //     { name: '盆部（左）', value: 22,selected: false },
            //     { name: '大腿（右前）', value: 25,selected: false },
            //     { name: '大腿（左前）', value: 27,selected: false },
            //     { name: '小腿（右前）', value: 29,selected: false },
            //     { name: '右上臂（内侧）', value: 3,selected: false },
            //     { name: '右手', value: 11,selected: false },
            //     { name: '腹部（前）', value: 15,selected: false },
            //     { name: '腰部（前）', value: 17,selected: false },
            //     { name: '头后部', value: 2,selected: false},
            //     { name: '颈背部', value: 14,selected: false },
            //     { name: '腹部（后）', value: 16 ,selected: false},
            //     { name: '右上臂（外侧）', value: 4 ,selected: false},
            //     { name: '左上臂（外侧）', value: 6 ,selected: false},
            //     { name: '右前臂（外侧）', value: 8 ,selected: false},
            //     { name: '左前臂（外侧）', value: 10,selected: false },
            //     { name: '腰部（后）', value: 18,selected: false},
            //     { name: '腰骶部（右）', value: 20,selected: false },
            //     { name: '腰骶部（左）', value: 23,selected: false },
            //     { name: '臀部（左）', value: 24 ,selected: false},
            //     { name: "臀部（右）", value: 21 ,selected: false},
            //     { name: '小腿（右后）', value: 30,selected: false },
            //     { name: '小腿（左后）', value: 32 ,selected: false},
            //     { name: '大腿（左后）', value: 28 ,selected: false},
            //     { name: '大腿（右后）', value: 26 ,selected: false},
            // ],
            adversereactions: [
                ["无", 0],
                ["便秘", 1],
                ["恶心呕吐", 2],
                ["谵妄", 3],
                ["过度镇静", 4],
                ["皮肤瘙痒", 5],
                ["呼吸抑制", 6],
                ["止汗", 7],
                ["利尿", 8],
                ["胃痉挛", 9],
                ["其他", -1],
            ],

            painhardsvgPath: 'svgs/pain_level.svg',
            painhardindex: 0,
            painhardslidermark: {
                0: '0',
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5',
                6: '6',
                7: '7',
                8: '8',
                9: '9',
                10: '10',
            },

            showincreaseitemdialog: false,
            increaseusedorrecipe: false,
            
            showdecideaccistresultdialog: false,
            showFeedbackDialog: false,
            feedbackstar: 3,


            painDrugTypeList: ["A", "B", "C", "D", "E", "F"],
            
            painDrugTypeNameList: {
                "A": "非甾体类",
                "B": "抗惊厥类/抗抑郁类",
                "C": "缓释强阿片",
                "D": "缓释弱阿片",
                "E": "即释强阿片",
                "F": "即释弱阿片",
            },

            userAdverseReactionList: [
                ["无", 0],
                ["便秘", 1],
                ["恶心呕吐", 2],
                ["谵妄", 3],
                ["过度镇静", 4],
                ["皮肤瘙痒", 5],
                ["呼吸抑制", 6],
                ["止汗", 7],
                ["利尿", 8],
                ["胃痉挛", 9],
                ["其他", -1],
            ],

            userAdverseReactiondrugs: [],
            drugunit: '',
            physicalLevelKV: [
                [0, 0, 0.099, 0.246],
                [0, 0, 0.105, 0.208],
                [0, 0, 0.074, 0.193],
                [0, 0, 0.092, 0.236],
                [0, 0, 0.086, 0.205],
            ],

            decisionRef: '',
            drugRecommendText: '',

            drugRecommendText2: '',

            drugIssueText: '',
            suggestionDrugs: [],
            suggestionTypeDrugs: [],
            diagnosticUUID: null,
            decisionTag: null,
            drugIssue: null,

            painScoreDict : {
                "-1": "基本排除神经病理性疼痛",
                "0": "基本排除神经病理性疼痛",
                "1": "不完全排除神经病理性疼痛",
                "2": "考虑患神经病理性疼痛",
                "3": "考虑患神经病理性疼痛",
                "4": "高度考虑患神经病理性疼痛",
                "5": "高度考虑患神经病理性疼痛"
            },
            painScore: "",

            loading: null,
        }
    },
    methods:{
        validatevalue(rule,value,callback){
            if(isNaN(value)){
                callback(new Error('该数据类型应为数值型数据'))
            }else{
                if(value === null || value === ""){
                    callback();
                }else{
                    if(rule.field === 'tel'){
                        if(value.length!==11){
                            callback(new Error('请输入正确的手机号格式'))
                        }else{
                            callback();
                        }
                    }else if(rule.field === 'age'){
                        if(value > 150 || value < 1){
                            callback(new Error('请输入正确的年龄，年龄不得大于150不得小于1'))
                        }else{
                            callback();
                        }
                    }else if(rule.field === 'height'){
                        if(value > 300 || value < 1){
                            callback(new Error('请输入正确的身高，身高<300'))
                        }else{
                            callback();
                        }
                    }else if(rule.field === 'weight'){
                        if(value > 1000 || value < 1){
                            callback(new Error('请输入正确的体重，体重<1000'))
                        }else{
                            callback();
                        }
                    }
                }
            }
        },
        namevalidatevalue(rule,value,callback){
            let testrule = /^([a-zA-Z+.?·?a-zA-Z+]{2,30}$|[\u4e00-\u9fa5+·?\u4e00-\u9fa5+]{2,30}$)/u
            if(value!="" && !testrule.test(value)){
                callback(new Error('请输入正确姓名格式'))
            }else{
                callback();
            }
        },
        increateitemvalidatevalue(rule,value,callback){
            if(rule.field === 'drugname'){
                callback();
            }else if(rule.field === 'dose'){
                if(value === null){
                    callback(new Error('请输入用药剂量'))
                }else{
                    if(isNaN(value)){
                        callback(new Error('该数据类型应为数值型数据'))
                    }else{
                        if(value > 1000 || value < 0){
                            callback(new Error('请输入正确剂量'))
                        }else{
                            callback();
                        }
                    }
                }
                
            }else if(rule.field === 'startendtime'){
                if(this.increaseusedorrecipe === false){
                    if(value === null){
                        callback(new Error('请选择用药起止时长'))
                    }else{
                        callback()
                    }
                }
            }else if(rule.field === 'drugtimestr'){
                if(this.increaseitemform.drugtimetype === null ){
                    callback(new Error('请选择药物频次'))
                }else if(this.increaseitemform.drugtimetype == 4 || this.increaseitemform.drugtimetype == 5){
                    callback();
                }else{
                    if(value === null ){
                        callback(new Error('请输入药物频次'))
                    }else{
                        if(isNaN(value)){
                            callback(new Error('该数据类型应为数值型数据'))
                        }else{
                            if(value > 1000 || value < 0){
                                callback(new Error('请输入正确药物频次'))
                            }else{
                                callback();
                            }
                        }
                    }
                    
                }   
                
            }
        },
        nextstep(towhere){
            this.activestep = towhere
            this.$nextTick(()=>{
                if(towhere === 2){
                    this.draweecharts();
                    this.drawsvgimg();
                }
            })
        },
        prestep(towhere){
            this.activestep = towhere
            this.$nextTick(()=>{
                if(towhere === 2){
                    this.draweecharts();
                    this.drawsvgimg();
                }
            })
        },
        commitbaseform(formref){
            this.$refs[formref].validate((valid)=>{
                if(valid){
                    this.nextstep(2);
                }else{

                }
            })
        },
        commitpainform(formref){
            this.$refs[formref].validate((valid)=>{
                if(valid){
                    this.nextstep(3);
                }else{

                }
            })
        },
        tableRowClassName({row,rowIndex}){
            if(row.selected){
                return 'selected-row'
            }else{
                return ''
            }
        },
        handleselectrow(row){
            row.selected = !row.selected
        },

        draweecharts(){
            let that = this;
            const chartDom = document.getElementById('echart1');
            let myChart = echarts.init(chartDom);
            myChart.clear()
            myChart.dispose();
            myChart = echarts.init(chartDom);
            axios.get('svgs/body_view.svg').then(svg => {
                echarts.registerMap('Beef_cuts_France', { svg: svg.data });
                let option = {
                    tooltip: {},
                    visualMap: {
                        show: false,
                        inRange: {
                        color: '#7cbafc'
                        },
                    },
                    series: [
                        {
                            name: '部位',
                            type: 'map',
                            map: 'Beef_cuts_France',
                            roam: true,
                            selectedMode: 'multiple',
                            select:{
                                disabled:false,
                                itemStyle:{
                                    areaColor: '#fb9595'
                                },
                                label:{
                                    show: true,
                                    formatter: function(params){
                                        if (params.data && params.data.value) {
                                            return params.data.value;
                                        }
                                    },
                                    textStyle: {
                                        fontSize: '80%'
                                    }
                                },
                            },
                            label:{
                                show: false
                            },
                            emphasis: {
                                label: {
                                    show: false,
                                },
                            },
                            itemStyle: {
                                emphasis:{
                                    areaColor: '#007bff'
                                }
                            },
                            data: that.bodypart,
                        }
                    ]
                };
                myChart.setOption(option);
                myChart.on('click', function (params) {
                    console.log({value:params.value,name:params.name})
                    const option = myChart.getOption();
                    const selectedname = params.name;
                    for(let index in that.bodypart){
                        if(that.bodypart[index].name === selectedname){
                            // that.bodypart[index].selected = !that.bodypart[index].selected;
                            if(that.bodypart[index].selected == true){
                                that.bodypart[index].selected = false
                                that.painform.painpart.splice(that.painform.painpart.indexOf(that.bodypart[index]),1)
                            }else{
                                that.bodypart[index].selected = true
                                that.painform.painpart.push(that.bodypart[index])
                            }
                            break
                        }
                    }
                    // that.painform.painpart = []
                    // for(let index in that.bodypart){
                    //     if(that.bodypart[index].selected){
                    //         that.painform.painpart.push(that.bodypart[index])
                    //     }
                    // }
                    that.painpartorchchange()
                })
            })
                
        },
        drawsvgimg(){
            setTimeout(()=>{
                this.changesvgstatus(this.painform.painmostlevel)
            },500)
        },
        clearsvgstatus(){
            try {
                const svgObject = this.$refs.painhardref.contentDocument;
                for(let i=0;i<=10;i++){
                    let labelElement = svgObject.querySelector('#pain-label-level'+i);
                    let flagElement = svgObject.querySelector('#pain-flag-level'+i);
                    let descElement = svgObject.querySelector('#pain-desc-level'+i);
                    labelElement.style.fontWeight = '';
                    descElement.style.fontWeight = '';
                    const centerX = 296.608;
                    const centerY = -366.834 + 31.97*i;
                    // 构建平移变换，将图形元素的原点移动到缩放中心
                    const translateTransform = `translate(${centerX} ${centerY})`;

                    // 构建完整的变换属性值
                    const transformValue = `${translateTransform}`;
                    flagElement.setAttribute("transform", transformValue);
                }
            } catch (error) {
                
            }
            
        },
        changesvgstatus(index){
            this.clearsvgstatus();
            try {
                const svgObject = this.$refs.painhardref.contentDocument;
                let labelElement = svgObject.querySelector('#pain-label-level'+index);
                let flagElement = svgObject.querySelector('#pain-flag-level'+index);
                let descElement = svgObject.querySelector('#pain-desc-level'+index);
                labelElement.style.fontWeight = 'bold';
                const centerX = 276.608;
                const centerY = -372.834 + 29.245*index;
                const scaleRatio = 1.1;
                const currentTransform = flagElement.getAttribute('transform');
                // 构建平移变换，将图形元素的原点移动到缩放中心
                const translateTransform = `translate(${centerX} ${centerY})`;

                // 构建缩放变换
                const scaleTransform = `scale(${scaleRatio})`;

                // 构建完整的变换属性值
                const transformValue = `${scaleTransform} ${translateTransform}`;
                flagElement.setAttribute("transform", transformValue);
                descElement.style.fontWeight = 'bold';
            } catch (error) {
                
            }
            
        },

        changeslider(e){
            this.changesvgstatus(this.painform.painmostlevel)
        },

        increaseitem() {
            this.$refs['increaseref'].validate((valid) => {
                if (valid) {
                    this.showincreaseitemdialog = false;
                    let increaseitemform = this.increaseitemform
                    if (increaseitemform.drugname == '' ||
                        increaseitemform.dose == null || increaseitemform.dose == '' ||
                        increaseitemform.drugtimestr == '' || increaseitemform.drugtimestr == null ||
                        increaseitemform.drugtimetype == '' || increaseitemform.drugtimetype == null) {
                        return
                    }
                    if (this.increaseusedorrecipe === false) {
                        if (increaseitemform.startendtime == null) {
                            return
                        }
                    }
                    let tempdrugtime = ""
                    if (increaseitemform.drugtimetype == 1) {
                        tempdrugtime = "一天" + increaseitemform.drugtimestr + "次"
                    } else if (increaseitemform.drugtimetype == 2) {
                        tempdrugtime = "每" + increaseitemform.drugtimestr + "小时/次"
                    } else if (increaseitemform.drugtimetype == 3) {
                        tempdrugtime = increaseitemform.drugtimestr + "天/贴"
                    } else if (increaseitemform.drugtimetype == 4) {
                        tempdrugtime = "prn（必要时）"
                    } else if (increaseitemform.drugtimetype == 5) {
                        tempdrugtime = "每晚"
                    }

                    let name = {
                        id: theutils.utils.G.availableDrugsDict[increaseitemform.drugname].toString(),
                        val: increaseitemform.drugname.toString()
                    }
                    let dose = {
                        val: increaseitemform.dose.toString(),
                        unit: increaseitemform.drugunit.toString()
                    }
                    let freq = {
                        id: increaseitemform.drugtimetype.toString(),
                        val: increaseitemform.drugtimestr.toString()
                    }
                    let duration = this.increaseusedorrecipe === false ? {
                        id: increaseitemform.startendtime.toString(),
                        val: increaseitemform.startendtime === 1 ? '>7' : '≤7'
                    } : ''
                    if (this.increaseusedorrecipe === false) {
                        if (this.editindex != null) {
                            this.historyform.useddrugtable[this.editindex] =
                            {
                                drugname: increaseitemform.drugname,
                                drugdose: increaseitemform.dose + increaseitemform.drugunit,
                                drugtime: tempdrugtime,
                                drugstartendtime: increaseitemform.startendtime === 1 ? '>7' : '≤7',
                                name: name,
                                dose: dose,
                                freq: freq,
                                duration: duration,
                                selected: false,
                                motodata: increaseitemform
                            }
                            this.editindex = null
                            return
                        }
                        this.historyform.useddrugtable.push({
                            drugname: increaseitemform.drugname,
                            drugdose: increaseitemform.dose + increaseitemform.drugunit,
                            drugtime: tempdrugtime,
                            drugstartendtime: increaseitemform.startendtime === 1 ? '>7' : '≤7',
                            name: name,
                            dose: dose,
                            freq: freq,
                            duration: duration,
                            selected: false,
                            motodata: increaseitemform
                        })
                    } else {
                        this.decisionform.decisiondrugtable.push({
                            drugname: increaseitemform.drugname,
                            drugdose: increaseitemform.dose + increaseitemform.drugunit,
                            drugtime: tempdrugtime,
                            name: name,
                            dose: dose,
                            freq: freq,
                            duration: duration,
                            selected: false
                        })
                    }
                }
            })

        },
        deleteitem(type){
            if(type === 'used'){
                this.historyform.useddrugtable = this.historyform.useddrugtable.filter((v)=> v.selected === false)
            }else if(type === 'recipe'){
                this.decisionform.decisiondrugtable = this.decisionform.decisiondrugtable.filter((v)=> v.selected === false)
            }
        },
        openincreasedrugdialog(type,formdata=null){
            if(type === 'used'){
                this.increaseusedorrecipe = false
            }else if(type === 'recipe'){
                this.increaseusedorrecipe = true
            }
            this.showincreaseitemdialog = true
            if(formdata !==null){
                this.increaseitemform = formdata
            }else{
                this.increaseitemform = {
                    drugname: '',
                    dose: null,
                    startendtime: null,
                    drugtimestr: null,
                    drugtimetype: null,
                    drugunit: '',
                }
            }
            // this.$refs['increaseref'].resetFields()
        },

        searchdrugs(queryString,cb){
            if(queryString === ""){
                cb([])
            }else{
                let templist1 = theutils.utils.G.availableDrugsPinYin2
                let templist2 = templist1.filter((v)=>v.indexOf(queryString)!==-1)
                let templist3 = []
                for(let i=0;i<templist2.length;i++){

                    let tempvalue = {
                        value: templist2[i].split("[")[0]
                    }
                    templist3.push(tempvalue)
                }
                cb(templist3)
            }
        },

        searchadverseReactiondrugs(queryString, cb) {
            if (queryString === "") {
                cb([])
            } else {
                let templist1 = theutils.utils.G.availableAdverseReactionDrugsPinYin2
                let templist2 = templist1.filter((v) => v.indexOf(queryString) !== -1)
                let templist3 = []
                for (let i = 0; i < templist2.length; i++) {

                    let tempvalue = {
                        value: templist2[i].split("[")[0]
                    }
                    templist3.push(tempvalue)
                }
                cb(templist3)
            }
        },

        searchIllness(queryString, cb) {
            if (queryString === "") {
                cb([])
            } else {
                let templist1 = theutils.utils.G.availableIllnessPinYin
                let templist2 = templist1.filter((v) => v.indexOf(queryString) !== -1)
                let templist3 = []
                for (let i = 0; i < templist2.length; i++) {

                    let tempvalue = {
                        value: templist2[i].split("[")[0]
                    }
                    templist3.push(tempvalue)
                }
                cb(templist3);
            }
        },

        handleselectadverseReactiondrug(item){
            console.log(item);
            this.historyform.adverseReactionDrugs.push(item.value);
            this.historyform.adverseReactionDrug = "";
        },

        handleselectdrug(item){
            console.log(item);
            this.increaseitemform.drugunit = ''
            let value = item.value;
            let index = theutils.utils.G.availableDrugs.indexOf(value);
            console.log(index);
            if(index !== -1){
                let unit = theutils.utils.G.PCNEData[index].unit;
                this.increaseitemform.drugunit = unit
            }
        },
        handleselectdrugtime(e){
            console.log(e)
            this.increaseitemform.drugtimetype = e
            this.$refs['increaseref'].validate((valid) => {})
        },
        handleselectillness(item){
            this.baseform.illnesslist.push(item.value)
            this.baseform.basedisease = ""
        },

        getBasicInfo(){
            let res = {}
            res.user_name = this.baseform.name
            res.uid = this.baseform.tel
            res.gender = this.baseform.gender
            res.age = this.baseform.age
            res.height = this.baseform.height != null ? parseFloat(this.baseform.height) : null
            res.weight = this.baseform.weight != null ? parseFloat(this.baseform.weight) : null
            res.job = this.baseform.career
            res.edu = this.baseform.edu
            res.special = this.baseform.specialpeople
            res.tel = this.baseform.tel
            res.tumor = this.baseform.cancerdialogsis
            res.tumor_metastasis = this.baseform.cancermovesituation
            res.tumor_treatment = this.baseform.tumortreatmentmethods
            res.illness = this.baseform.illnesslist.join(",")
            res.liver_function = this.baseform.liverfunction
            res.kidney_function = this.baseform.renalfunction
            res.cardiac_function = this.baseform.heartfunction
            res.allergy = this.baseform.allergyhistory
            res.physical_q1 = this.baseform.action.toString()
            res.physical_q2 = this.baseform.takecare.toString()
            res.physical_q3 = this.baseform.activities.toString()
            res.physical_q4 = this.baseform.painornot.toString()
            res.physical_q5 = this.baseform.anxious.toString()
            res.physical_score = this.baseform.physicalscore
            return res
        },
        getPainAssessmentInfo() {
            let res = {}
            res.causes = this.painform.painreason.join(",")
            res.body_parts = this.getBodyList().join(",")
            res.character = this.painform.paincharacter.join(",")
            res.level = this.getMostLevel()
            res.pain_extra = this.painform.painextra == null ? "" : this.painform.painextra
            res.aggravating_factors = this.painform.aggravationfactors.join(",")
            res.relief_factors = this.painform.relieffactors.join(",")
            res.breakout_type = Math.abs(this.painform.breakouttype).toString()
            res.breakout_freq = this.painform.breakoutfreq == null ? "3" : this.painform.breakoutfreq.toString()
            res.illness = this.painform.painillness.join(",")
            res.symptom = this.painform.painstatus.join(",")
            return res
        },
        getBasicDecision(decisionTag, drugIssue = null){
            let res = {}
            res.recmd = decisionTag
            if(drugIssue!==null){
                res.previous_medication_issue = JSON.stringify(drugIssue)
            }else{
                res.previous_medication_issue = ""
            }
            return res
        },
        getPrevMedicationInfo() {
            let res = {}
            res.compliance_q1 = this.historyform.complianceq1.toString()
            res.compliance_q2 = this.historyform.complianceq2.toString()
            res.compliance_q3 = this.historyform.complianceq3.toString()
            res.compliance_q4 = this.historyform.complianceq4.toString()
            res.adverse_reaction = this.historyform.adverseReaction.join(",")
            res.adverse_reaction_drugs = this.historyform.adverseReactionDrugs.join(',')
            let allDrugs = this.getAllUsedDrugs()
            let tableData = this.getDrugTableData(allDrugs, "used")
            res.drug_table = tableData
            return res
        },
        getAllUsedDrugs(){
            let res = []
            let tabledata = this.historyform.useddrugtable
            tabledata.forEach(element=>{
                let drugData = this.getDataFromRowData(element)
                res.push(drugData)
            })
            return res
        },
        getDataFromRowData(rowdata){
            let res = {
                name: rowdata.name,
                dose: rowdata.dose,
                freq: rowdata.freq,
                duration: rowdata.duration
            }
            return res
        },
        getDrugTableData(allDrugs,type="used"){
            // 获取药物表中数据 
            let tableData = []
            allDrugs.forEach(drug => {
                let index = theutils.utils.G.availableDrugs.indexOf(drug.name.val)
                if(index !== -1){
                    let druginfo = theutils.utils.G.PCNEData[index]
                    let row = {}
                    row.drug_name = druginfo.name
                    row.spec = druginfo.spec
                    row.dose = parseFloat(drug.dose.val)
                    row.dose_unit = drug.dose.unit
                    row.freq = drug.freq.val
                    row.freq_unit = drug.freq.id
                    row.duration = type === "used" ? drug.duration.id : ""
                    tableData.push(row)
                }
            });
            return tableData
        },
        showResult(decisionTag="#",drugIssue=null){
            let drugIssueInfo = theutils.drugCheck.genDrugIssueInfo(drugIssue)
            let [decisionType,decisionId] = decisionTag.split("#")
            this.getDecisionRef(decisionType,decisionId)
            if(decisionId){
                this.drugRecommendText = theutils.decsionText[decisionType][decisionId]
            }
            this.drugIssueText = "无"
            if(drugIssueInfo.length>0){
                this.drugIssueText = drugIssueInfo.join("、")
            }
            this.showdecideaccistresultdialog = true
            this.decisionTag = decisionTag
            this.drugIssue = drugIssue
        },
        getDecisionRef(decisionType,decisionId){
            //证据来源
            this.decisionRef= ""
            if(decisionType === "s1"){
                let refData = theutils.s1RefText[decisionId]
                let thetext = ""
                for(let i=0;i<refData.length;i++){
                    let data = refData[i]
                    let num = i+1
                    thetext += num+ "." + data.ref + "\n" +data.content + "\n"
                }
                this.decisionRef = thetext
            }
        },
        getAdverseReactionDrugList(){
            return this.historyform.adverseReactionDrugs
        },
        getAdverseReactionTag(){
            return this.historyform.adverseReaction
        },
        async generateDrugSuggestionsS1(decisionId){
            let tempdata = {
                "疼痛部位": this.getBodyList(),
                "疼痛性质": this.painform.paincharacter,
                "疼痛强度": this.painform.painmostlevel,
                "疼痛次数": this.getBreakOutTimesFeat(),
                "症状": this.painform.painstatus,
                "疾病": this.getPainillnessFeat('s1'),
                "决策": decisionId
            }
            try{
                await getSuggestionDrugs("s1",tempdata).then((res)=>{
                    console.log(res)
                    if(res.status === 200){
                        let data = res.data
                        let tempdruglist = []
                        let tempdrugtypelist = []
                        console.log(data)
                        for(let i=0;i<data.length;i++){
                            // let drugitem = theutils.utils.G.availableDrugsDict2[data[i]]
                            let drugitem = theutils.utils.G.availableDrugsDict2[data[i].drug_id]
                            if(drugitem!==undefined){
                                tempdruglist.push(drugitem)

                                if(tempdrugtypelist.length === 0){
                                    tempdrugtypelist.push(
                                        {
                                            type: data[i].type,
                                            drugs: [drugitem]
                                        }
                                    )
                                }else{
                                    let intype = false;
                                    tempdrugtypelist.forEach(element => {
                                        if(element.type == data[i].type){
                                            element.drugs.push(drugitem)
                                            intype = true;
                                        }
                                    });
                                    if(intype == false){
                                        tempdrugtypelist.push(
                                            {
                                                type: data[i].type,
                                                drugs: [drugitem]
                                            }
                                        )
                                    }
                                }
                            }
                        }
                        this.suggestionDrugs = tempdruglist;
                        this.suggestionTypeDrugs = tempdrugtypelist;
                    }
                }).catch(error=>{
                    console.log(error)
                })
            }catch(e){

            }
        },
        async generateDrugSuggestionsS2(feat,allUsedDrugs){
            let tempdata = {
                "肝功能": this.getLiverFeat(),
                "肾功能": this.getRenalFeat(),
                "疼痛强度": feat[0],
                "爆发痛类型": feat[1],
                "爆发痛频率": feat[2],
                "既往用药": allUsedDrugs,
                "既往用药种类": feat[3],
                "依从性": feat[4],
                "疾病": parseInt(this.getIllnessAssessmentInfo()),
                "疼痛部位": this.getBodyList(),
                "疼痛性质": this.painform.paincharacter
            }
            let resDecision = null;
            try{
                await getSuggestionDrugs("s2",tempdata).then((res)=>{
                    console.log(res)
                    if(res.status === 200){
                        // let data = res.data.drugs;
                        let decisionIdfromnonsteroid = res.data.nonsteroid.decision; //非甾体
                        let decisionIdfromopioid = res.data.opioid.decision; //阿片类
                        resDecision = decisionIdfromopioid + "#" + decisionIdfromnonsteroid;
                        this.drugRecommendText2 = theutils.decisionS2Text[decisionIdfromnonsteroid]
                        let data = res.data.nonsteroid.drugs.concat(res.data.opioid.drugs)
                        let tempdruglist = []
                        let tempdrugtypelist = []
                        console.log(data)
                        for(let i=0;i<data.length;i++){
                            let drugitem = theutils.utils.G.availableDrugsDict2[data[i].drug_id]
                            if(drugitem!==undefined){
                                tempdruglist.push(drugitem)
                                if(tempdrugtypelist.length === 0){
                                    tempdrugtypelist.push(
                                        {
                                            type: data[i].type,
                                            drugs: [drugitem]
                                        }
                                    )
                                }else{
                                    let intype = false;
                                    tempdrugtypelist.forEach(element => {
                                        if(element.type == data[i].type){
                                            element.drugs.push(drugitem)
                                            intype = true;
                                        }
                                    });
                                    if(intype == false){
                                        tempdrugtypelist.push(
                                            {
                                                type: data[i].type,
                                                drugs: [drugitem]
                                            }
                                        )
                                    }
                                }
                            }
                        }
                        this.suggestionDrugs = tempdruglist;
                        this.suggestionTypeDrugs = tempdrugtypelist;
                        
                    }
                }).catch(error=>{
                    console.log(error)
                    this.$message({
                        message: "获取推荐用药失败",
                        type: "warning"
                    })
                }).finally(()=>{
                    return resDecision
                })
            }catch(e){

            }finally{
                return resDecision
            }
        },

        getOnlineTag(){
            return this.serverOnlineValue;
        },
        getHello(){
            try {
                getHello().then(res=>{
                    if(res.status === 200){
                        this.serverOnlineValue = true
                    }
                }).catch(error=>{
                    console.log(error)
                })
            } catch (error) {
            }
            
        },
        async saveS1(decisionTag,decisionId){
            await this.generateDrugSuggestionsS1(decisionId)
            if(this.getOnlineTag()){
                let data1 = this.getBasicInfo();
                let data2 = this.getPainAssessmentInfo();
                let data3 = this.getBasicDecision(decisionTag);
                try {
                    let res = await this.createDiagnostic(data1,data2,data3)
                    if(res){
                        console.log("提交成功");
                        // this.generateDrugSuggestions(decisionId)
                        this.showResult(decisionTag)
                    }else{
                        console.log("提交失败");
                    }
                } catch (error) {
                    console.log("提交失败");
                } finally{
                    this.loading.close()
                }
            }else{
                console.log("pds api is not ready");
                this.showResult(decisionTag)
                this.loading.close()
            }
        },
        async saveS2(decisionTag, drugIssue){
            if(this.getOnlineTag()){
                let data1 = this.getBasicInfo();
                let data2 = this.getPainAssessmentInfo();
                let data3 = this.getBasicDecision(decisionTag,drugIssue);
                let data4 = this.getPrevMedicationInfo();
                try {
                    let res = await this.createDiagnostic(data1,data2,data3,data4)
                    if(res){
                        console.log("提交成功");
                        this.showResult(decisionTag,drugIssue)
                    }else{
                        console.log("提交失败");
                    }
                } catch (error) {
                    
                } finally {
                    this.loading.close()
                }
            }else{
                console.log("pds api is not ready");
                this.showResult(decisionTag,drugIssue)
                this.loading.close()
            }
        },
        processS1(){
            //既往未使用止痛药的情况
            this.loading = ElLoading.service({
                lock: true,
                text: '提交数据中',
                background: 'rgba(0,0,0,0.7)',
            })
            try {
                let bodyList = this.getBodyList();
                let chList = this.getChList();
                let mostLevel = this.getMostLevel();
                let feat = extractS1Feat(mostLevel,bodyList,chList)
                console.log(feat)
                interS1(feat).then((res)=>{
                    let decisionId = res[0].toString()
                    console.log(res)
                    let templist = this.getPainillnessFeat('s1');
                    // if(this.painform.painillness.includes(2) &&  this.painform.painillness.includes(3)){
                    if(templist.includes(2) &&  templist.includes(3)){
                        if(decisionId === '1'){
                            decisionId = '3'
                        }else if(decisionId === '5'){
                            decisionId = '2'
                        }
                    }
                    // let decisionTag = `s1#${res[0]}`
                    // this.saveS1(decisionTag,res[0].toString())
                    let decisionTag = `s1#${decisionId}`
                    this.saveS1(decisionTag,decisionId)
                })
                
            } catch (error) {
                
            } finally{
                // loading.close()
            }
            
        },
        async processS2(){
            //既往使用过止痛药的情况
            this.loading = ElLoading.service({
                lock: true,
                text: '提交数据中',
                background: 'rgba(0,0,0,0.7)',
            })
            try {
                let allUsedDrugs = this.getAllUsedDrugs()
                if(!theutils.drugCheck.usedDrugInputCheck(allUsedDrugs)){
                    ElMessage({
                        message: '请填写完整用药信息',
                        type: 'warning'
                    })
                    this.loading.close()
                    return
                }
                let drugIssue = theutils.drugCheck.genDrugIssue(allUsedDrugs,true,this.getAdverseReactionDrugList(),this.getAdverseReactionTag())
                let mostLevel = this.getMostLevelFeat()
                let breakOutType = this.getBreakOutTypeFeat()
                let breakOutTimes = this.getBreakOutTimesFeat()
                let allUsedDrugsID = allUsedDrugs.map((v) => v.name.id)
                let decDrugType = this.getDecDrugType(allUsedDrugsID)
                if(decDrugType === undefined){
                    ElMessage({
                        message: '请填写完整用药信息, 且至少选择一种镇痛药物',
                        type: 'warning'
                    })
                    this.loading.close()
                    return 
                }
                let compliance = this.getCompliance()
                let feat = [
                    mostLevel,
                    breakOutType,
                    breakOutTimes,
                    decDrugType,
                    compliance
                ]
                if(feat === null){
                    this.loading.close()
                    return
                }
                // interS2(feat).then(async res=>{
                //     console.log(res)
                    let resdecision = await this.generateDrugSuggestionsS2(feat,allUsedDrugsID)
                    // let decisionTag = `s2#${res[0]}`
                    if(resdecision != null){
                        let decisionTag = "s2#" + resdecision
                        this.saveS2(decisionTag,drugIssue)
                    }else{
                        this.$message({
                            message: "未能获取推荐用药或决策，请重新提交",
                            type: "warning"
                        })
                        this.loading.close()
                    }
                // })
            } catch (error) {
                
            }finally{
                // loading.close()
            }
        },
        finalsubmit(){
            //提交按钮
            this.drugRecommendText = ""
            this.suggestionDrugs = []
            if(this.historyform.havausedrug == '是'){
                this.$refs['historyref'].validate((valid1)=>{
                    if(valid1){
                        this.$refs['painref'].validate((valid2)=>{
                            if(valid2){
                                this.$refs['baseref'].validate((valid3)=>{
                                    if(valid3){
                                        this.processS2()
                                    }else{
                                        this.nextstep(1)
                                    }
                                })
                            }else{
                                this.nextstep(2)
                            }
                        })
                    }else{
                    }
                })
            }else if(this.historyform.havausedrug == '否'){
                this.$refs['painref'].validate((valid2) => {
                    if (valid2) {
                        this.$refs['baseref'].validate((valid3) => {
                            if (valid3) {
                                this.processS1()
                            } else {
                                this.nextstep(1)
                            }
                        })
                    } else {
                        this.nextstep(2)
                    }
                })
            }
        },
        getBreakOutTypeFeat(){
            let type = Math.abs(this.painform.breakouttype)
            let times = this.painform.breakoutfreq == null ? 3 : this.painform.breakoutfreq
            if(type === 2 && times === 3){
                return 1
            }
            return type
        },
        getBreakOutTimesFeat(){
            let type = Math.abs(this.painform.breakouttype)
            let times = this.painform.breakoutfreq == null ? 3 : this.painform.breakoutfreq
            if(type === 1){
                return 2
            }
            if(type === 3){
                return 3
            }
            if(type === 2 && times === 3){
                return 1
            }
            return times
        },

        getLiverFeat(){
            let liver = this.baseform.liverfunction
            if(liver === "3"){
                return 1
            }else{
                return 0
            }
        },
        getRenalFeat(){
            let renal = this.baseform.renalfunction
            if(renal === "3"){
                return 1
            }else{
                return 0
            }
        },
        getPainillnessFeat(Swho){
            let templist = []
            if(Swho == 's1'){
                if(this.painform.painillness.includes(1)) {
                    templist.push(1)
                }
                if(this.painform.painillness.includes(4)){
                    templist.push(2)
                }   
                if(this.painform.painillness.includes(5) || this.painform.painillness.includes(6)){
                    templist.push(3)
                }
            }else if(Swho == 's2'){

            }
            return templist;
        },
        getIllnessAssessmentInfo(){
            let res = "";
            let jyouken1 = false;
            let jyouken2 = false;
            if(this.painform.painillness.includes(2) || this.painform.painillness.includes(4)){
                jyouken1 = true;
            }
            if(this.painform.painillness.includes(5) || this.painform.painillness.includes(6)){
                jyouken2 = true;
            }
            if(jyouken1){
                res = "1"
            }
            if(jyouken2){
                res = "2"
            }
            if(jyouken1 && jyouken2){
                res = "3"
            }
            if(!jyouken1 && !jyouken2){
                res = "0"
            }
            return res
        },


        getBodyList(){
            //疼痛部位value
            let tempbodylist = this.bodypart;
            let templist = [];
            tempbodylist.forEach(element => {
                if(element.selected){
                    templist.push(element.value);
                }
            });
            return templist;
        },
        getChList(){
            //疼痛性质List
            let templist = [];
            if(this.painform.paincharacter==null){
                return templist
            }
            for(let i=0;i<this.painform.paincharacter.length;i++){
                templist.push(this.painform.paincharacter[i])
            }
            return templist
        },
        getMostLevel(){
            //疼痛强度
            return this.painform.painmostlevel;
        },
        getMostLevelFeat(){
            let level = this.getMostLevel();
            if (level > 0 && level <= 3) {
                return 1;
            }
            if (level > 3 && level <= 6) {
                return 2;
            }
            if (level > 6 && level <= 10) {
                return 3;
            }
            return 0;
        },
        painpartorchchange(){
            //疼痛性质或疼痛部位进行选择时
            let bodyList = this.getBodyList()
            let chlist = this.painform.paincharacter
            this.painScore = ""
            if(bodyList.length > 0 && chlist.length >0){
                const testChracter = [2, 15, 16, 13, 19,];
                const testBodySet = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
                    11, 12, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35]);
                let score = 0;
                for (const t of testChracter) {
                    if (chlist.includes(t)) {
                        score += 1;
                    }
                }
                const joints = (() => new Set(bodyList.filter((x) => testBodySet.has(x))))();
                if (joints.size === 0) {
                    score -= 1;
                }

                const scoreText = this.genPainScoreText(score);
                this.painScore = scoreText
            }
        },

        edituseddrug(e){
            console.log(e)
            let tempitem = this.historyform.useddrugtable[e]
            // this.increaseitemform = tempitem.motodata
            let tempdata = tempitem.motodata
            this.editindex = e
            this.openincreasedrugdialog('used',tempdata)
        },
        deleteuseddrug(e){
            //删除已服过的药物
            this.historyform.useddrugtable.splice(e,1)
        },

        deletetag(index){
            //删除不良反应用药
            this.historyform.adverseReactionDrugs.splice(index,1);
        },
        deleteillness(index){
            //删除基础疾病
            this.baseform.illnesslist.splice(index,1);
        },

        async createDiagnostic(basicInfoData=null,painAssessmentInfoData=null,diagnosticData=null,prevMedicationInfoData=null){
            if(basicInfoData===null){
                return
            }
            let patientId = null
            await postPatientBasicInfo(basicInfoData).then(res=>{
                patientId = res.data.id
            })
            if(patientId === null){
                return
            }
            let diagnosticPostData = {
                patient_basic_info_id: patientId
            }
            let diagnosticUUID = null
            await postDiagnostic(diagnosticPostData).then(res=>{
                diagnosticUUID = res.data.uuid
            })
            if(diagnosticUUID === null){
                return
            }
            if(painAssessmentInfoData === null){
                return
            }
            painAssessmentInfoData.diagnostic_uuid = diagnosticUUID
            let painAssessmentId = null
            await postPainAssessmentInfo(painAssessmentInfoData).then(res=>{
                painAssessmentId = res.data
            })
            if(painAssessmentId === null){
                return
            }
            if(diagnosticData === null){
                return
            }
            let updateDiagnosticUUID = null
            await updateDiagnosticByUUId(diagnosticUUID,diagnosticData).then(res=>{
                updateDiagnosticUUID = res.data.uuid
            })
            if(updateDiagnosticUUID === null){
                return
            }

            if(prevMedicationInfoData !==null){
                prevMedicationInfoData.diagnostic_uuid = diagnosticUUID
                let prevMedicationId = null
                await postPrevMedicationInfo(prevMedicationInfoData).then(res=>{
                    prevMedicationId = res.data.id
                })
                if(prevMedicationId === null){
                    return
                }
            }
            this.diagnosticUUID = diagnosticUUID
            return diagnosticUUID;
        },

        userphysicalchange(){
            //计算身体状况效用值
            if(this.baseform.action!==null && 
            this.baseform.takecare!==null &&
            this.baseform.activities!==null &&
            this.baseform.painornot!==null &&
            this.baseform.anxious!==null){
                let values = [this.baseform.action,this.baseform.takecare,this.baseform.activities,this.baseform.painornot,this.baseform.anxious];
                let N3 = values.includes(3) ? 0.22 : 0;
                let constParam = 0.039;
                let q1 = this.physicalLevelKV[0][values[0]];
                let q2 = this.physicalLevelKV[1][values[1]];
                let q3 = this.physicalLevelKV[2][values[2]];
                let q4 = this.physicalLevelKV[3][values[3]];
                let q5 = this.physicalLevelKV[4][values[4]];
                let result = 1.0 - N3 - constParam - (q1 + q2 + q3 + q4 + q5)
                this.baseform.physicalscore = result.toFixed(3)
            }
        },
        getDecDrugType(allUsedDrugsID) {
            //查看既往用药种类
            let counter = this.getTypeCounter(allUsedDrugsID);
            let decDrugType = this.getDecDrugTypeFromCounter(counter);
            return decDrugType;
        },
        getTypeCounter(drugIDList) {
            //计算用药种类数量
            let counter = this.initTypeCounter();
            drugIDList.forEach((drugID) => {
                let drugClassList = this.getDrugClassList(drugID);
                this.painDrugTypeList.forEach((painDrugType) => {
                    if (this.isDrugTypeFromDrugClass(drugClassList, painDrugType)) {
                        counter[painDrugType] = counter[painDrugType] + 1;
                    }
                });
            });
            return counter;
        },
        isDrugTypeFromDrugClass(drugClassList, drugType) {
            if (drugClassList) {
                let typeRegex = new RegExp(`^(${drugType}).*`, "u");
                if (drugClassList.filter((v) => typeRegex.test(v)).length >= 1) {
                    return true;
                }
            }
            return false;
        },
        initTypeCounter() {
            let counter = {};

            this.painDrugTypeList.forEach((painDrugType) => {
                counter[painDrugType] = 0;
            });

            return counter;
        },
        getDrugClassList(drugID) {
            let drugInfo = theutils.utils.G.PCNEData.find((v) => {
                return v.id === drugID;
            });
            if (drugInfo) {
                return drugInfo.class.split("/");
            }
            return undefined;
        },
        getCompliance() {
            let inputs = [this.historyform.complianceq1,this.historyform.complianceq2,this.historyform.complianceq3,this.historyform.complianceq4]
            let scoreList = inputs.map((v) => {
                let val = parseInt(v);
                return val;
            });
            let scoreSum = scoreList.reduce(
                (previousVal, currentVal) => previousVal + currentVal,
                0
            );
            if (scoreSum >= 3) {
                return 1;
            } else {
                return 2;
            }
        },
        getDecDrugTypeFromCounter(counter) {
            // c,e
            if (counter.C > 0 && counter.D >= 0 && counter.E > 0 && counter.F >= 0) {
                return 6;
            }

            // d,e
            if (counter.C === 0 && counter.D > 0 && counter.E > 0 && counter.F >= 0) {
                return 7;
            }

            //  f,d
            if (counter.C === 0 && counter.D > 0 && counter.E === 0 && counter.F > 0) {
                return 8;
            }

            // e,f
            if (counter.C === 0 && counter.D === 0 && counter.E > 0 && counter.F > 0) {
                return 9;
            }

            // c,d
            if (counter.C > 0 && counter.D > 0 && counter.E === 0 && counter.F === 0) {
                return 10;
            }

            // c,f
            if (counter.C > 0 && counter.D >= 0 && counter.E === 0 && counter.F > 0) {
                return 11;
            }

            if (counter.C > 1 && counter.D === 0 && counter.E === 0 && counter.F === 0) {
                return 12;
            }

            if (counter.C === 0 && counter.D === 0 && counter.E === 0 && counter.F > 1) {
                return 13;
            }

            if (counter.C === 0 && counter.D === 0 && counter.E > 1 && counter.F === 0) {
                return 14;
            }

            // 1-5
            if (
                (counter.A > 0 || counter.B > 0) &&
                counter.C === 0 &&
                counter.D === 0 &&
                counter.E === 0 &&
                counter.F === 0
            ) {
                return 1;
            }

            if (
                (counter.A > 0 || counter.B > 0 || (counter.F === 0 && counter.D === 0)) &&
                counter.C === 1 &&
                counter.E === 0
            ) {
                return 2;
            }

            if (
                (counter.A >= 0 || counter.B >= 0) &&
                counter.C === 0 &&
                counter.D >= 1 &&
                counter.E === 0 &&
                counter.F === 0
            ) {
                return 3;
            }

            if (
                (counter.A > 0 || counter.B > 0 || counter.F === 0) &&
                counter.C === 0 &&
                counter.D === 0 &&
                counter.E === 1
            ) {
                return 4;
            }

            if (
                (counter.A >= 0 || counter.B >= 0) &&
                counter.C === 0 &&
                counter.D === 0 &&
                counter.E === 0 &&
                counter.F === 1
            ) {
                return 5;
            }

            return undefined;
        },
        genPainScoreText(score) {
            if (score in this.painScoreDict) {
                return `${score}分（${this.painScoreDict[score]}）`;
            } else {
                return "未知";
            }
        },
        async submitDecision(decisionTag,drugIssue){
            let uuid = this.getDiagnosticUUID();
            if (uuid) {
                let data = this.getDecisionInfo(decisionTag, drugIssue);
                data.diagnostic_uuid = uuid;
                try {
                    await postDecisionInfo(data).then(res=>{
                        if (res) {
                            console.log(res)
                            ElMessage({
                                message: '决策提交成功',
                                type: 'success'
                            })
                        }
                    });
                } catch (error) {                    
                }
            }
        },
        async submitFeedback(data){

        },
        getDiagnosticUUID(){
            return this.diagnosticUUID
        },
        getDecisionInfo(decisionTag, drugIssue = null){
            let res = this.getBasicDecision(decisionTag,drugIssue)
            let allDrugs = this.getAllRecipeDrugs();
            let tableData = this.getDrugTableData(allDrugs,"recipe")
            res.drug_table = tableData
            return res
        },
        getAllRecipeDrugs(){
            let res = []
            let tabledata = this.decisionform.decisiondrugtable
            tabledata.forEach(element=>{
                let drugData = this.getDataFromRowData(element)
                res.push(drugData)
            })
            return res
        },

        handlesubmitDecision(){
            //提交决策
            let allDrugs = this.getAllRecipeDrugs()
            if(!theutils.drugCheck.recipeDrugInputCheck(allDrugs)){
                ElMessage({
                    message: '请检查药品输入',
                    type: 'warning'
                })
                return
            }
            if(this.decisionform.PCNEcheck === false){
                let recipeDrugIssue = theutils.drugCheck.genDrugIssue(allDrugs,false)
                let recipeDrugIssueInfo = theutils.drugCheck.genDrugIssueInfo(recipeDrugIssue)
                if(recipeDrugIssueInfo.length>0){
                    ElMessage({
                        message: recipeDrugIssueInfo.join('、'),
                        type: 'warning'
                    })
                    return
                }
            }
            this.submitDecision(this.decisionTag,this.drugIssue)
            this.showdecideaccistresultdialog = false
            this.showFeedbackDialog = true
        },
        async handlesubmitFeedback(){
            this.showFeedbackDialog = false
            let data = {
                feedback_score: this.feedbackstar
            }
            let uuid = this.getDiagnosticUUID()
            if(uuid){
                await updateDiagnosticByUUId(uuid,data).then(res=>{
                    console.log(res)
                    ElMessage({
                        message: '反馈提交成功',
                        type: 'success'
                    })
                })
            }
        },
        resetAllform(){
            this.baseform.physicalscore = null
            for(let index in this.bodypart){
                this.bodypart[index].selected = false       
            }
            this.draweecharts()
            this.$refs['historyref'].resetFields()
            this.$refs['baseref'].resetFields()
            this.$refs['painref'].resetFields()
        },
        sethealth(e){
            if(e===true){
                this.baseform.cancerdialogsis = "无"
                this.baseform.liverfunction = "1"
                this.baseform.renalfunction = "1"
                this.baseform.heartfunction = "1"
                this.baseform.allergyhistory = "无"
            }
        },
        whenfeedbackclose(){
            ElMessageBox.confirm(
                "是否新的录入",
                "刷新页面",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: 'success',
                    center: true
                }
            ).then(()=>{
                this.resetAllform()
                this.nextstep(1)
            }).catch(()=>{

            })
        },

        //选项选到'无'时
        changetowu(list, tabitem, careone, updateone) {
            let templist = list
            if (tabitem === "无") {
                if (list.includes(careone)) {
                    templist = list.filter(item => item == careone)
                } else {
                }
            } else {
                if (list.includes(careone)) {
                    templist = list.filter(item => item != careone)
                }
            }
            if (updateone === '1') {
                this.historyform.adverseReaction = templist
            } else if (updateone === '2') {
                this.painform.aggravationfactors = templist
            } else if (updateone === '3') {
                this.painform.relieffactors = templist
            } else if(updateone === '4') {
                this.painform.painillness = templist
            } else if(updateone === '5'){
                this.painform.painstatus = templist
            }
        },

        breakouttypetowu(e){
            if(e == 3){
                console.log(e)
                this.painform.breakoutfreq = 3
            }
        },
        breakouttimetowu(e){
            if(e == 3){
                console.log(e)
                this.painform.breakouttype = 3
            }
        }
    },
    computed:{
        serverOnlineValue:{
            get(){
                return this.$store.state.S2Decision.serverOnline
            },
            set(value){
                this.$store.commit("S2Decision/setServerOnline",value)
            }
        }
    },
    created(){
        this.getHello();
        console.log(theutils.utils.G)
    }
}
</script>
<style scoped>
    .titleclass{
        height: 65px;
        padding: 10px 10px 10px 30px;
        max-width: 1200px;
        margin-left: auto;
        margin-right:auto;
    }
    .contentclass{
        height: calc(100vh - 275px);
        padding: 10px 5px 10px 40px;
        max-width: 1200px;
        margin-left: auto;
        margin-right:auto;
    }
    .btnareaclass{
        height: 40px;
        padding: 10px;
        max-width: 1200px;
        margin-left: auto;
        margin-right:auto;
    }
    .formtitleclass{
        margin: 5px;
        /* font-size: large; */
        font-weight: bold;
    }
    .formtitleclass2{
        margin: 5px;
        /* font-size: large; */
    }
    .el-table >>> .selected-row {
        background: rgb(221, 221, 221);
    }
    .dialogclass /deep/ .el-dialog__body{
        padding: 10px
    }
</style>