const S2Decision = {
    namespaced: true,
    state: {
        serverOnline: false,
    },
    getters: {

    },
    mutations: {
        setServerOnline(state,value){
            state.serverOnline = value;
        },
    },
    actions: {

    },
}
export default S2Decision