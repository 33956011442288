import {request,request2,request3} from '@/api';

export function getHello(){
    return request({
        url: 'api',
        method: 'get'
    })
}

export function postDiagnostic(data){
    return request({
        url: 'diagnostics',
        method: 'post',
        data: data
    })
}

export function updateDiagnosticByUUId(uuid,data){
    return request({
        url: 'diagnostics/uuid/'+ uuid,
        method: 'put',
        data: data
    })
}

export function postPatientBasicInfo(data){
    return request({
        url: 'patients',
        method: 'post',
        data: data
    })
}

export function postPainAssessmentInfo(data){
    return request({
        url: 'pain_assessments',
        method: 'post',
        data: data
    })
}

export function postPrevMedicationInfo(data){
    return request({
        url: 'previous_medications',
        method: 'post',
        data: data
    })
}

export function postDecisionInfo(data){
    return request({
        url: 'decisions',
        method: 'post',
        data: data
    })
}

export function getSuggestionDrugs(form,data){
    return request2({
        url: '/predict/'+form,
        method: 'post',
        data: data
    })
}